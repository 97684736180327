<template>
  <div class="deyatelnost">
    <!-- 1 -->
    <div class="title">Деятельность</div>
    <!-- 2 -->
    <div class="bgimg"></div>
    <!-- 3 -->
    <div class="zhivot">
      <div class="title">
        <img class="cowicon" src="../assets/deyatelnost/cowicon.png" alt="" />
        Животноводство
      </div>
      <div class="subtitle1">
        Производство молока и племенное животноводство
      </div>
      <div class="subtitle2">
        {{ zhivot }}
      </div>
      <img class="zhivotrt" src="../assets/deyatelnost/zhivotrt.png" alt="" />
      <img class="zhivotrb" src="../assets/deyatelnost/zhivotrb.png" alt="" />
      <img class="zhivotc" src="../assets/deyatelnost/zhivotc.png" alt="" />
      <img class="zhivotcbg" src="../assets/deyatelnost/zhivotcbg.png" alt="" />
      <div class="subtitle3">
        <img class="cowicon2" src="../assets/deyatelnost/cowicon2.png" alt="" />
        Вы можете связаться с нами по вопросам приобретения племенного скота.
      </div>
    </div>
    <!-- 4 -->
    <div class="milk">
      <div class="title">
        <img class="milkicon" src="../assets/deyatelnost/milkicon.png" alt="" />
        Производство сырого молока
      </div>
      <div class="subtitle1">
        {{ milk }}
      </div>
      <img class="milklt" src="../assets/deyatelnost/milklt.png" alt="" />
      <img class="milklb" src="../assets/deyatelnost/milklb.png" alt="" />
      <img class="milkc" src="../assets/deyatelnost/milkc.png" alt="" />
      <img class="milkcbg" src="../assets/deyatelnost/milkcbg.png" alt="" />
    </div>
    <!-- 5 -->
    <div class="rast">
      <div class="title">
        <img class="rasticon" src="../assets/deyatelnost/rasticon.png" alt="" />
        Растениеводсвто
      </div>
      <div class="subtitle1">
        {{ rast }}
      </div>
      <img class="rastrt" src="../assets/deyatelnost/rastrt.png" alt="" />
      <img class="rastrb" src="../assets/deyatelnost/rastrb.png" alt="" />
      <img class="rastrbbg" src="../assets/deyatelnost/rastrbbg.png" alt="" />
      <img class="rastct" src="../assets/deyatelnost/rastct.png" alt="" />
      <img class="rastctbg" src="../assets/deyatelnost/rastctbg.png" alt="" />
      <img class="rastcb" src="../assets/deyatelnost/rastcb.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      zhivot: "",
      milk: "",
      rast: "",
      rast: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await fetch("https://cd20321.tmweb.ru/api/articles/");
      const finalRes = await res.json();
      this.zhivot = finalRes[9].text;
      this.milk = finalRes[10].text;
      this.rast = finalRes[11].text;
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 416px) {
  .deyatelnost {
    .title {
      font-weight: 600;
      font-size: 100px;
      line-height: 144px;
      text-align: center;
    }
    .bgimg {
      background-image: url("../assets/deyatelnost/bg.png");
      background-size: cover;
      height: 538px;
    }
    .zhivot {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(6, auto);
      gap: 20px;
      margin: 50px 0;
      .title {
        grid-column: 2 / span 3;
        display: flex;
        font-weight: 500;
        font-size: 40px;
        line-height: 58px;
        align-items: center;
        .cowicon {
          width: 51px;
          height: 30px;
          margin-right: 20px;
        }
      }
      .subtitle1 {
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        grid-column: 2 / span 3;
        grid-row: 2;
      }
      .subtitle2 {
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        grid-column: 2 / span 3;
        grid-row: 3;
      }
      .zhivotrt {
        grid-column: 5 / span 2;
        grid-row: 1 / span 4;
        width: 474px;
      }
      .zhivotrb {
        grid-column: 5;
        grid-row: 5;
        width: 286px;
      }
      .zhivotc {
        grid-column: 3 / span 2;
        grid-row: 4 / span 2;
        width: 296px;
        justify-self: flex-end;
      }
      .zhivotcbg {
        grid-column: 3 / span 2;
        grid-row: 4 / span 2;
        width: 374px;
        z-index: -1;
        justify-self: flex-end;
      }
      .subtitle3 {
        grid-column: 1 / span 2;
        grid-row: 5;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        display: flex;
        flex-direction: column;
        width: 278px;
        align-items: center;
        justify-self: center;
        justify-content: flex-end;
        .cowicon2 {
          width: 50px;
          object-fit: cover;
          margin-bottom: 18px;
        }
      }
    }
    .milk {
      display: grid;
      grid-template-columns: minmax(auto, 140px) repeat(3, 1fr);
      grid-template-rows: repeat(5, auto);
      gap: 20px;
      margin: 50px 0;
      .title {
        grid-column: 3 / span 2;
        grid-row: 1;
        justify-self: flex-start;
        padding-left: 100px;
        font-weight: 500;
        font-size: 40px;
        line-height: 58px;
        .milkicon {
          width: 28px;
          height: 50px;
        }
      }
      .subtitle1 {
        grid-column: 3 / span 2;
        grid-row: 2;
        padding-left: 100px;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
      }
      .milklt {
        grid-column: 1 / span 2;
        grid-row: 1 / span 3;
        justify-self: flex-end;
        width: 394px;
        object-fit: cover;
      }

      .milklb {
        grid-column: 1 / span 2;
        grid-row: 4 / span 2;
        justify-self: flex-end;
        width: 286px;
        object-fit: cover;
      }
      .milkc {
        grid-column: 3;
        grid-row: 4 / span 2;
        width: 286px;
        object-fit: cover;
      }
      .milkcbg {
        grid-column: 3;
        grid-row: 4 / span 2;
        width: 374px;
        object-fit: cover;
        z-index: -1;
      }
    }
    .rast {
      display: grid;
      grid-template-columns: minmax(auto, 200px) repeat(2, 1fr);
      grid-template-rows: minmax(auto, auto) minmax(320px, auto) repeat(2, auto);
      column-gap: 30px;
      margin: 50px 0;
      .title {
        font-weight: 600;
        font-size: 34px;
        line-height: 49px;
        grid-column: 2;
        grid-row: 1;
        justify-self: start;
        padding-bottom: 20px;
        .rasticon {
          width: 35px;
          height: 50px;
        }
      }
      .subtitle1 {
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        grid-column: 2;
        grid-row: 2;
      }
      .rastrt {
        width: 372px;
        grid-column: 3;
        grid-row: 1 / span 2;
        align-self: end;
      }
      .rastrb {
        width: 372px;
        grid-column: 3;
        grid-row: 3;
      }
      .rastrbbg {
        width: 443px;
        grid-column: 3;
        grid-row: 3;
        z-index: -1;
      }
      .rastct {
        width: 374px;
        grid-column: 2;
        grid-row: 3;
        justify-self: flex-end;
        align-self: flex-end;
      }
      .rastctbg {
        width: 443px;
        grid-column: 2;
        grid-row: 3;
        z-index: -1;
        justify-self: flex-end;
      }
      .rastcb {
        width: 374px;
        grid-column: 2;
        grid-row: 4;
        justify-self: flex-end;
      }
    }
  }
}
@media (max-width: 415px) {
  .deyatelnost {
    .title {
      font-weight: 600;
      font-size: 50px;
      line-height: 72px;
      text-align: center;
    }
    .bgimg {
      background-image: url("../assets/deyatelnost/bgm.png");
      background-size: cover;
      height: 516px;
    }
    .zhivot {
      display: grid;
      grid-template-columns: minmax(auto, 335px);
      grid-template-rows: repeat(6, auto);
      gap: 20px;
      margin: 50px 0;
      justify-content: center;
      justify-items: center;
      .title {
        grid-column: 1;
        grid-row: 1;
        display: flex;
        font-weight: 500;
        font-size: 32px;
        line-height: 46px;
        align-items: center;
        justify-self: flex-start;
        .cowicon {
          width: 51px;
          height: 30px;
          margin-right: 20px;
        }
      }
      .subtitle1 {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        grid-column: 1;
        grid-row: 2;
      }
      .subtitle2 {
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        grid-column: 1;
        grid-row: 3;
      }
      .zhivotrt {
        grid-column: 1;
        grid-row: 4;
        width: 249px;
      }
      .zhivotrb {
        grid-column: 1;
        grid-row: 5;
        width: 150px;
        justify-self: flex-end;
        padding: 15px 0 0 0;
      }
      .zhivotc {
        grid-column: 1;
        grid-row: 5;
        width: 150px;
        justify-self: flex-start;
        padding: 15px 0 0 15px;
      }
      .zhivotcbg {
        grid-column: 1;
        grid-row: 5;
        width: 185px;
        z-index: -1;
        justify-self: flex-start;
      }
      .subtitle3 {
        grid-column: 1;
        grid-row: 6;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        display: flex;
        flex-direction: column;
        width: 278px;
        align-items: center;
        justify-self: center;
        justify-content: flex-end;
        .cowicon2 {
          width: 50px;
          object-fit: cover;
          margin-bottom: 18px;
        }
      }
    }
    .milk {
      display: grid;
      grid-template-columns: minmax(auto, 335px);
      grid-template-rows: repeat(4, auto);
      gap: 20px;
      margin: 50px 0;
      justify-content: center;
      .title {
        grid-column: 1;
        grid-row: 1;
        justify-self: flex-start;
        font-weight: 500;
        font-size: 32px;
        line-height: 32px;
        text-align: left;
        display: flex;
        align-items: center;
        column-gap: 20px;
        .milkicon {
          width: 28px;
          height: 50px;
        }
      }
      .subtitle1 {
        grid-column: 1;
        grid-row: 2;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
      }
      .milklt {
        grid-column: 1;
        grid-row: 3;
        justify-self: center;
        width: 249px;
        object-fit: cover;
      }

      .milklb {
        grid-column: 1;
        grid-row: 4;
        justify-self: flex-start;
        width: 150px;
        object-fit: cover;
        padding: 15px 0 0 0;
      }
      .milkc {
        grid-column: 1;
        grid-row: 4;
        width: 150px;
        object-fit: cover;
        justify-self: flex-end;
        padding: 15px 15px 0 0;
      }
      .milkcbg {
        grid-column: 1;
        grid-row: 4;
        width: 185px;
        object-fit: cover;
        z-index: -1;
        justify-self: flex-end;
      }
    }
    .rast {
      display: grid;
      grid-template-columns: minmax(auto, 335px);
      grid-template-rows: repeat(4, auto);
      column-gap: 30px;
      row-gap: 30px;
      margin: 50px 0;
      justify-content: center;
      .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 46px;
        grid-column: 1;
        grid-row: 1;
        justify-self: start;
        .rasticon {
          width: 35px;
          height: 50px;
        }
      }
      .subtitle1 {
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        grid-column: 1;
        grid-row: 2;
      }
      .rastrt {
        width: 150px;
        grid-column: 1;
        grid-row: 3;
        justify-self: flex-end;
        align-self: flex-end;
      }
      .rastrb {
        width: 150px;
        grid-column: 1;
        grid-row: 4;
        justify-self: flex-end;
        padding: 0 15px 0 0;
      }
      .rastrbbg {
        width: 185px;
        grid-column: 1;
        grid-row: 4;
        z-index: -1;
        justify-self: flex-end;
      }
      .rastct {
        width: 150px;
        grid-column: 1;
        grid-row: 3;
        justify-self: flex-start;
        align-self: end;
        padding: 0 0 0 15px;
      }
      .rastctbg {
        width: 185px;
        grid-column: 1;
        grid-row: 3;
        z-index: -1;
        justify-self: flex-start;
      }
      .rastcb {
        width: 150px;
        grid-column: 1;
        grid-row: 4;
        justify-self: flex-start;
      }
    }
  }
}
</style>
